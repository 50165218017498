<template>
  <div></div>
</template>

<script setup>
</script>

<style scoped>
div {
  width: auto;
  height: 2px;
  background-color: var(--bg3);
  margin: 16px 0;
}
</style>
